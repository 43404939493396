import React from "react"

// Styles
import styles from "../styles/components/separator.module.scss"

// Images
import sectionSeparator from "../images/section-separator.svg"
import greySeparator from "../images/grey-separator.svg"


const Separator = ({ grey }) => {
  return (
    <div className={styles.main}>
      {!grey ? (
        <img src={sectionSeparator} alt="" className={styles.separator} />
      ) : (
        <img src={greySeparator} alt="" className={styles.separator} />
      )}
    </div>
  )
}

export default Separator
