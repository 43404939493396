import React from "react"

// Styles
import styles from "../styles/components/title.module.scss"

export default ({ text }) => (
  <div>
    <h1 className={styles.text}>{text}</h1>
  </div>
)
