import React from "react"
import { Carousel } from "react-responsive-carousel"

// Images
import image1 from "../images/about1.jpg"
import image2 from "../images/about2.jpg"
import image3 from "../images/about3.jpg"
import image4 from "../images/about4.jpg"

// Styles
import styles from "../styles/components/about.module.scss"

export const CarouselMain = () => (
  <Carousel
    infiniteLoop={true}
    autoPlay={true}
    interval={5000}
    showThumbs={false}
    showStatus={false}
    showArrows={false}
    style={{ backgroundColor: "#ffffff" }}
  >
    <div className={styles.content}>
      <h1>Join our Gym Family</h1>
      <p>
        Lifetime Fitness is more than just a gym. We are a family that's there
        to help you reach your goals. The best way to get where you want to go
        is to have fun along the way - and it's always more fun doing it
        alongside people that care!
      </p>
    </div>
    <div className={styles.content}>
      <h1>Personalized Training</h1>
      <p>
        Every member at Lifetime Fitness gets a free personalized diet and
        training program that suits their individual lifestyle, food preferences
        and fitness goals. We believe in helping each individual based on their
        goals and lifestyle.
      </p>
    </div>
    <div className={styles.content}>
      <h1>Caring Environment</h1>
      <p>
        There's no sense in doing something you're not going to enjoy. It's so
        important that you really enjoy the environment you're in because that's
        what will ultimately keep you consistent. Lifestyle Fitness is like a
        loving second home!
      </p>
    </div>
  </Carousel>
)

export const HeaderCarousel = () => (
  <Carousel
    infiniteLoop={true}
    autoPlay={true}
    interval={3500}
    showIndicators={false}
    showThumbs={false}
    showStatus={false}
    showArrows={true}
    style={{ backgroundColor: "#ffffff" }}
  >
    <div className={styles.slide}>
      <p>OPEN FROM 4 AM TO 12 MIDNIGHT</p>
    </div>
    <div className={styles.slide}>
      <p>PROFESSIONAL INSTRUCTORS</p>
    </div>
    <div className={styles.slide}>
      <p>JUICE &amp; SUPPLEMENT BAR</p>
    </div>
  </Carousel>
)

export const AboutCarousel = () => (
  <Carousel
    infiniteLoop={true}
    autoPlay={true}
    interval={3500}
    showIndicators={true}
    showThumbs={false}
    showStatus={false}
    showArrows={true}
    style={{ backgroundColor: "#ffffff" }}
  >
    <img src={image1} alt="image1" />
    <img src={image2} alt="image2" />
    <img src={image3} alt="image3" />
    <img src={image4} alt="image4" />
  </Carousel>
)
